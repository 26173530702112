/* eslint-disable react-hooks/exhaustive-deps */
import {AlertOutlined} from '@ant-design/icons'
import {Card, Title, BarList} from '@tremor/react'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

export default function Alerts24Hours({tenantId}) {
  const [alerts, setAlerts] = useState([])
  const fetchAlerts = useFetchGet('alerts')

  /**
   * /v1/alerts?start=date&count=alert_type
   *
   *     "alerts": [
   *     {
   *         "alert_type": "temp_warning",
   *         "count": 4090
   *     },
   *     {
   *         "alert_type": "temp_critical",
   *         "count": 4467
   *     }
   *     ]
   * @param start Beginning of select range
   */
  const getAlerts = start => {
    const params = Params({tid: tenantId, start, count: 'alert_type'})
    fetchAlerts(params, response => {
      setAlerts(response.alerts)
      console.log('alerts', response.alerts)
    })
  }

  useEffect(() => {
    if (tenantId) getAlerts(moment().local().add(-24, 'hours'))
  }, [tenantId])

  const data = useMemo(() => {
    const alertTypes = alerts.reduce((acc, curr) => {
      acc[curr.alert_type] = curr.count
      return acc
    }, {})
    const counts = [
      {name: 'Warnings', value: alertTypes['temp_warning'] || 0},
      {name: 'Criticals', value: alertTypes['temp_critical'] || 0}
    ]
    console.log({counts})
    return counts
  }, [alerts])

  return (
    <Card className="max-w-sm">
      <Title className="m-0 text-lg">
        <AlertOutlined /> Alerts (24 hours)
      </Title>
      <BarList data={data} color={'orange'} />
    </Card>
  )
}
